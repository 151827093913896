import { useTranslation } from 'react-i18next';

interface PaymentBreakdownProps {
  subtotal: number;
  vatPercentage: number;
  vatAmount: number;
  total: number;

}

export default function PaymentBreakdown({
  subtotal,
  vatPercentage,
  vatAmount,
  total,
}: PaymentBreakdownProps) {
  const { t } = useTranslation();
  return (
    <dl className='space-y-4 text-sm font-medium text-secondary'>
      <div className='flex justify-between'>
        <dt>
          {t('checkout.orderSummary.subtotal')}
        </dt>
        <dd className='text-black'>
          {subtotal.toFixed(2)}
          {' '}
          {t('shared.currency')}
        </dd>
      </div>
      <div className='flex justify-between'>
        <dt>
          {t('checkout.orderSummary.vat', { vat: vatPercentage })}
        </dt>
        <dd className='text-black'>
          {vatAmount.toFixed(2)}
          {' '}
          {t('shared.currency')}
        </dd>
      </div>
      <div className='flex justify-between border-t border-gray-200 pt-2'>
        <dt>
          {' '}
          {t('checkout.orderSummary.total')}
        </dt>
        <dd className='text-black'>
          {total.toFixed(2)}
          {' '}
          {t('shared.currency')}
        </dd>
      </div>
    </dl>
  );
}
