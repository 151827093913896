import { useState, useEffect } from 'react';
import Hero from './components/hero';
import Products from './components/products';
import Api from '../../services';
import { Product } from '../../types/retail-product';

export default function Home() {
  const [products, setProducts] = useState<Product[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await Api.retailProducts.getRetailProducts();
        setProducts(response.data);
      } finally {
        setLoading(false);
      }
    };

    fetchProducts();
  }, []);
  return (
    <>
      <Hero />
      <Products data={products} loading={loading} />
    </>
  );
}
