import { Fragment, useEffect, useState } from 'react';
import {
  Disclosure, DisclosureButton, DisclosurePanel, Transition, Menu, MenuButton, MenuItem, MenuItems,
} from '@headlessui/react';
import {
  ArrowRightStartOnRectangleIcon, Bars3Icon, UserCircleIcon, XMarkIcon,
} from '@heroicons/react/24/outline';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import logo from '../../assets/images/logo.svg';
import { AppDispatch, RootState } from '../../redux/store';
import { getProfile } from '../../redux/profile';
import { logout } from '../../redux/auth';
import Cart from '../../views/cart';
import CartIcon from '../cart-icon';
import useCartActions from '../../hooks/use-cart-actions';
import i18n from '../../i18n';
import AR from '../../assets/images/ar.png';
import EN from '../../assets/images/en.png';

export default function Header() {
  const { locale } = useParams();
  const navigate = useNavigate();
  const [isCartOpen, setIsCartOpen] = useState(false);
  const profile = useSelector((state: RootState) => state.profile.data);
  const authenticated = useSelector((state: RootState) => state.auth.authenticated);
  const itemCount = useSelector((state: RootState) => state.cart.itemCount);
  const { getCart } = useCartActions();
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();

  useEffect(() => {
    if (!authenticated) return;

    dispatch(getProfile());
  }, [dispatch, authenticated]);

  useEffect(() => {
    getCart();
  }, []);

  const logoutUser = () => {
    const refreshToken = localStorage.getItem('refresh_token');
    const data = {
      client_id: 'chaizer-webstore',
      client_secret: 'chaizer',
      token_type_hint: 'refresh_token',
      token: refreshToken,
    };
    dispatch(logout(data));
  };

  const switchLanguage = () => {
    const newLocale = locale === 'ar' ? 'en' : 'ar';
    i18n.changeLanguage(newLocale).then(() => {
      localStorage.setItem('locale', newLocale);

      const currentPath = window.location.pathname;
      const newPath = currentPath.replace(/^\/app\/[a-z]{2}/, `/${newLocale}`);
      navigate(newPath, { replace: true });

      document.documentElement.lang = newLocale;
      const newDir = newLocale === 'ar' ? 'rtl' : 'ltr';
      document.documentElement.dir = newDir;

      window.location.reload();
    });
  };

  return (
    <>
      <Disclosure as='header' className='bg-white'>
        {({ open }) => (
          <>
            <div className='mx-auto max-w-7xl px-2 sm:px-4 lg:divide-y lg:divide-gray-200 lg:px-0'>
              <div className='relative flex h-16 justify-between'>
                <div className='relative z-10 flex px-2 lg:px-0'>
                  <Link to={`/${locale}`} className='flex flex-shrink-0 items-center'>
                    <img className='block h-6 w-auto' src={logo} alt='Chaizer' />
                  </Link>
                </div>
                <div className='relative z-10 flex items-center lg:hidden justify-end'>
                  {/* Mobile menu button or login link */}
                  {authenticated ? (
                    <div className='relative flex items-center gap-3'>
                      <CartIcon itemCount={itemCount} onClick={() => setIsCartOpen(true)} />
                      <DisclosureButton className='relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-1 focus:ring-inset focus:ring-gray-900'>
                        <span className='absolute -inset-0.5' />
                        <span className='sr-only'>Open menu</span>
                        {open ? (
                          <XMarkIcon className='block h-6 w-6' aria-hidden='true' />
                        ) : (
                          <Bars3Icon className='block h-6 w-6' aria-hidden='true' />
                        )}
                      </DisclosureButton>
                    </div>
                  ) : (
                    <div className='relative flex items-center gap-3'>
                      <CartIcon itemCount={itemCount} onClick={() => setIsCartOpen(true)} />
                      <Link
                        to='/auth/login'
                        className='inline-block lg:hidden rounded-md bg-primary px-3 py-2 text-sm font-medium leading-6 text-white shadow-sm hover:bg-lime-600 disabled:bg-lime-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-lime-600'
                      >
                        تسجيل الدخول
                      </Link>
                    </div>
                  )}
                </div>
                <div className='hidden lg:relative lg:z-10 lg:flex lg:items-center'>
                  {/* Profile dropdown */}
                  <button
                    onClick={switchLanguage}
                    type='button'
                    className='p-2 flex flex-row items-center text-md font-medium text-black focus:outline-none rtl:ml-3 mr-3'
                  >
                    <span className='rtl:ml-1 mr-1'>
                      <img src={locale === 'ar' ? EN : AR} className='w-5 h-5' alt='' />
                    </span>
                    <span className='text-sm'>{locale === 'ar' ? 'English' : 'العربية'}</span>
                  </button>
                  {authenticated ? (
                    <Menu as='div' className='relative flex-shrink-0'>
                      <div className='flex items-center gap-4'>
                        <CartIcon itemCount={itemCount} onClick={() => setIsCartOpen(true)} />
                        <MenuButton className='relative flex rounded-full bg-white focus:outline-none focus:ring-1 focus:ring-gray-900 focus:ring-offset-2'>
                          <span className='absolute -inset-1.5' />
                          <span className='sr-only'>Open user menu</span>
                          {profile?.profile_picture_url ? (
                            <img className='h-8 w-8 rounded-full' src={profile?.profile_picture_url} alt='user' />
                          ) : (
                            <UserCircleIcon className='h-8 w-8 rounded-full text-black' />
                          )}
                        </MenuButton>
                      </div>
                      <Transition
                        as={Fragment}
                        enter='transition ease-out duration-100'
                        enterFrom='transform opacity-0 scale-95'
                        enterTo='transform opacity-100 scale-100'
                        leave='transition ease-in duration-75'
                        leaveFrom='transform opacity-100 scale-100'
                        leaveTo='transform opacity-0 scale-95'
                      >
                        <MenuItems className='absolute divide-y divide-gray-100 rtl:left-0 right-0 z-10 mt-2 w-48 rtl:origin-top-left origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'>
                          <MenuItem>
                            <Link to={`/${locale}/account/update`} className='block px-4 py-2 text-sm text-black'>
                              {t('header.settings')}
                            </Link>
                          </MenuItem>
                          <MenuItem className='w-full' as='button' onClick={logoutUser}>
                            <p className='flex items-center px-4 py-2 text-sm text-red-500'>
                              <ArrowRightStartOnRectangleIcon className='block h-5 w-5 me-1' />
                              {t('header.logout')}
                            </p>
                          </MenuItem>
                        </MenuItems>
                      </Transition>
                    </Menu>
                  ) : (
                    <div className='relative flex items-center gap-3'>
                      <CartIcon itemCount={itemCount} onClick={() => setIsCartOpen(true)} />
                      <Link
                        to='/auth/login'
                        className='rounded-md bg-primary px-3 py-2 text-sm font-medium leading-6 text-white shadow-sm hover:bg-lime-600 disabled:bg-lime-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-lime-600'
                      >
                        تسجيل الدخول
                      </Link>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <DisclosurePanel as='nav' className='lg:hidden' aria-label='Global'>
              <div className='border-t border-gray-200 pb-3 pt-4'>
                <div className='flex items-center px-4'>
                  <div className='flex-shrink-0'>
                    <img className='h-10 w-10 rounded-full' src={profile?.profile_picture_url} alt='' />
                  </div>
                  <div className='mr-3'>
                    <div className='text-base font-medium text-gray-800'>{profile?.full_name}</div>
                    <div className='text-sm font-medium text-gray-500'>{profile?.email}</div>
                  </div>
                </div>
                <div className='mt-3 space-y-1 px-2'>
                  <DisclosureButton
                    as={Link}
                    to='/account/update'
                    className='block rounded-md px-3 py-2 text-base font-medium text-gray-500 hover:bg-gray-50 hover:text-gray-900'
                  >
                    {t('header.settings')}
                  </DisclosureButton>
                  <DisclosureButton
                    onClick={logoutUser}
                    className='flex items-center rounded-md px-3 py-2 text-base font-medium text-red-500 hover:bg-gray-50 hover:text-gray-900'
                  >
                    <ArrowRightStartOnRectangleIcon className='block h-5 w-5 me-1' />
                    {t('header.logout')}
                  </DisclosureButton>
                </div>
                <div className='px-4'>
                  <button
                    onClick={switchLanguage}
                    type='button'
                    className='p-2 flex flex-row items-center text-md font-medium text-black focus:outline-none rtl:ml-3 mr-3'
                  >
                    <span className='rtl:ml-1 mr-1'>
                      <img src={locale === 'ar' ? EN : AR} className='w-5 h-5' alt='' />
                    </span>
                    <span className='text-sm'>{locale === 'ar' ? 'English' : 'العربية'}</span>
                  </button>
                </div>
              </div>
            </DisclosurePanel>
          </>
        )}
      </Disclosure>
      <Cart isOpen={isCartOpen} toggleDrawer={setIsCartOpen} />
    </>
  );
}
