import { TrashIcon } from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';

interface ProductCardProps {
  data: any;
  logo: string;
  handleIncrement: (id: number) => void;
  handleDecrement: (id: number) => void;
  handleRemove: (id: number) => void;
}

export default function ProductCard({
  data, logo, handleDecrement, handleIncrement, handleRemove,
}: ProductCardProps) {
  const { t } = useTranslation();
  return (
    <li key={data.id} className='flex py-6'>
      <div className='h-24 w-24 flex-shrink-0 overflow-hidden rounded-md border border-gray-200'>
        <img
          alt={data.name}
          src={logo}
          className='h-full w-full object-cover object-center'
        />
      </div>

      <div className='ltr:ml-4 rtl:mr-4 flex flex-1 flex-col'>
        <div>
          <div className='flex justify-between text-base font-medium'>
            <h3 className='flex-1'>
              <p className='block text-black'>{data.name}</p>
            </h3>
            <p className='ltr:ml-4 rtl:mr-4 text-primary whitespace-nowrap'>
              {data.total_price || data.price}
              {' '}
              {t('shared.currency')}
            </p>
          </div>
        </div>
        <div className='flex flex-1 items-end justify-between text-sm'>
          <div className='flex items-center'>
            <p className='text-secondary'>
              {t('productDetails.productInfo.quantity')}
              :
            </p>
            <button
              type='button'
              onClick={() => handleDecrement(data.id)}
              className='px-2 border rounded text-secondary hover:bg-primary hover:text-white ltr:ml-2 rtl:mr-2'
            >
              -
            </button>
            <span className='px-2'>{data.quantity}</span>
            <button
              type='button'
              onClick={() => handleIncrement(data.id)}
              className='px-2 border rounded text-secondary hover:bg-primary hover:text-white'
            >
              +
            </button>
          </div>
          <div>
            <button
              type='button'
              onClick={() => handleRemove(data.id)}
              className='flex items-center justify-center bg-red-50 p-2 rounded'
            >
              <span className='sr-only'>Remove</span>
              <TrashIcon aria-hidden='true' className='h-4 w-4 text-red-600' />
            </button>
          </div>
        </div>
      </div>
    </li>
  );
}
