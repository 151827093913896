import {
  Tab,
  TabGroup,
  TabList,
  TabPanel,
  TabPanels,
} from '@headlessui/react';
import { PlayIcon } from '@heroicons/react/24/solid';
import VideoWithOverlay from './video-with-overlay';

interface MediaItem {
  id: number;
  url: string;
  filename: string;
  content_type: string;
  alt?: string;
}
export default function ProductMedia({ images, videos }: { images: MediaItem[], videos: MediaItem[] }) {
  const mediaItems = [...images, ...videos];

  return (
    <TabGroup className='flex flex-col-reverse'>
      <div className='mx-auto mt-6 w-full max-w-2xl sm:block lg:max-w-none'>
        <TabList className='grid grid-cols-4 gap-6'>
          {mediaItems.map((media) => (
            <Tab
              key={media.id}
              className='group relative flex h-24 cursor-pointer items-center justify-center rounded-md bg-white text-sm font-medium uppercase text-gray-900 hover:bg-gray-50 focus:outline-none'
            >
              <span className='sr-only'>{media.filename}</span>
              <span className='absolute inset-0 overflow-hidden rounded-md'>
                {media.content_type.startsWith('image/') ? (
                  <img alt='' src={media.url} className='h-full w-full object-cover object-center' />
                ) : (
                  <div className='relative h-full w-full'>
                    <video className='h-full w-full object-cover object-center'>
                      <source src={media.url} type={media.content_type} />
                      <track kind='captions' src='' label='English' />
                      Your browser does not support the video tag.
                    </video>
                    <div className='absolute inset-0 flex items-center justify-center bg-black bg-opacity-30'>
                      <PlayIcon className='w-12 h-12 text-white opacity-80' />
                    </div>
                  </div>
                )}
              </span>
              <span
                aria-hidden='true'
                className='pointer-events-none absolute inset-0 rounded-md ring-2 ring-transparent ring-offset-2 group-data-[selected]:ring-primary'
              />
            </Tab>
          ))}
        </TabList>
      </div>
      <TabPanels className='aspect-h-1 aspect-w-1 w-full'>
        {mediaItems.map((media) => (
          <TabPanel key={media.id}>
            {media.content_type.startsWith('image/') ? (
              <img
                alt={media.alt}
                src={media.url}
                className='h-full w-full object-cover object-center rounded-lg'
              />
            ) : (
              <VideoWithOverlay media={media} />
            )}
          </TabPanel>
        ))}
      </TabPanels>
    </TabGroup>
  );
}
