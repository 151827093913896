import classNames from 'classnames';
import { CheckIcon } from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';
import AymakanImage from '../../../assets/images/aymakan.png';
import CloudshelfImage from '../../../assets/images/cloudshelf.png';
import EmptyPlaceholder from '../../../components/empty-placholder';
import emptyPlaceholderIcon from '../../../assets/images/empty-data.svg';

interface DeliveryProvider {
  id: number;
  name: string;
}

interface DeliveryProvidersProps {
  providers: DeliveryProvider[] | null | undefined;
  selectedProviderId: number | null;
  onSelect: (id: number) => void;
  loading: boolean;
}

const providerImages: { [key: string]: string } = {
  aymakan: AymakanImage,
  cloudshelf: CloudshelfImage,
};

function DeliveryProviders({
  providers, selectedProviderId, onSelect, loading,
}: DeliveryProvidersProps) {
  const { t } = useTranslation();
  if (loading) {
    return (
      <div className='border-b border-gray-200 pb-6 mb-4'>
        <div className='grid grid-cols-1 gap-4'>
          {[...Array(2)].map((item) => (
            <div key={item} className='w-full h-20 bg-gray-300 rounded-md animate-pulse' />
          ))}
        </div>
      </div>
    );
  }

  if (providers?.length === 0) {
    return (
      <>
        <h2 className='text-lg font-medium text-black mb-4'>{t('checkout.deliveryProviders.title')}</h2>
        <EmptyPlaceholder
          title={t('checkout.deliveryProviders.unavailable.title')}
          subtitle={t('checkout.deliveryProviders.unavailable.message')}
          imageSrc={emptyPlaceholderIcon}
          fullHeight={false}
        />
      </>

    );
  }
  return (
    <div className='border-b border-gray-200 pb-6 mb-4'>
      <h2 className='text-lg font-medium text-black mb-4'>{t('checkout.deliveryProviders.title')}</h2>
      <div className='grid grid-cols-1 gap-4'>
        {providers?.map((provider) => {
          const providerImage = providerImages[provider.name.toLowerCase()] || providerImages.default;
          return (
            <div
              key={provider.id}
              className={classNames(
                'p-4 rounded-lg border border-gray-200 bg-white shadow-sm',
                { 'border-primary border-2': selectedProviderId === provider.id },
              )}
              onClick={() => onSelect(provider.id)}
              onKeyDown={(e) => e.key === 'Enter' && onSelect(provider.id)}
              role='button'
              tabIndex={0}
            >
              <div className='flex items-center'>
                <img
                  src={providerImage}
                  alt={provider.name}
                  className='h-12 w-20 object-contain mr-4 rtl:mr-0 rtl:ml-4'
                />
                <p className='text-md font-medium text-black flex-1'>{provider.name}</p>
                {selectedProviderId === provider.id ? (
                  <CheckIcon aria-hidden='true' className='h-6 w-6 text-primary' />
                ) : (
                  <span className='text-sm text-gray-500'>{t('checkout.deliveryProviders.selectProvider')}</span>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default DeliveryProviders;
