import { useState } from 'react';
import { QuoteResponse } from '../types/quote-response';
import Api from '../services';

export default function useQuote(cartId: string | null) {
  const [quoteData, setQuoteData] = useState<QuoteResponse | null>(null);
  const [loading, setLoading] = useState(false);

  const fetchQuote = async (latitude: number, longitude: number, deliveryProviderId: number | null = null, promoCode: string | null = null): Promise<QuoteResponse | undefined> => {
    if (!cartId) {
      return undefined;
    }

    setLoading(true);
    try {
      const response = await Api.purchases.purchaseQuote({
        delivery_lat: latitude,
        delivery_lon: longitude,
        cart_id: cartId,
        delivery_provider_id: deliveryProviderId,
        promo_code: promoCode,
      });

      setQuoteData(response);
      return response?.data as QuoteResponse;
    } catch (error) {
      console.error('Failed to fetch quote:', error);
      throw error;
    } finally {
      setLoading(false);
    }
  };

  return { quoteData, loading, fetchQuote };
}
