import { useDispatch } from 'react-redux';
import { fetchCart, updateCart } from '../redux/cart-slice';
import { AppDispatch } from '../redux/store';
import Api from '../services';

export default function useCartActions() {
  const dispatch = useDispatch<AppDispatch>();

  const getCart = async () => {
    const cartId = localStorage.getItem('cart_id');
    if (!cartId) return;

    dispatch(fetchCart(cartId));
  };

  const handleQuantityChange = async (productId: number, increment: boolean) => {
    const cartId = localStorage.getItem('cart_id');
    if (!cartId) return;

    if (increment) {
      const response = await Api.cart.increaseProductQuantity(cartId, productId.toString());
      dispatch(updateCart(response.data.attributes));
    } else {
      const response = await Api.cart.decreaseProductQuantity(cartId, productId.toString());
      dispatch(updateCart(response.data.attributes));
    }
  };

  const handleRemoveProduct = async (productId: number) => {
    const cartId = localStorage.getItem('cart_id');
    if (!cartId) return;
    const response = await Api.cart.removeProductFromCart(cartId, productId.toString());
    dispatch(updateCart(response.data.attributes));
  };

  return {
    handleQuantityChange, handleRemoveProduct, getCart,
  };
}
