import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../redux/store';
import { updateCart } from '../redux/cart-slice';
import Api from '../services';

const useCart = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [isAddingToCart, setIsAddingToCart] = useState<boolean>(false);

  const addItemToCart = async (productId: string, quantity: number) => {
    setIsAddingToCart(true);
    try {
      const cartId = await localStorage.getItem('cart_id');
      const response = await Api.cart.addProductToCart(cartId, productId, quantity);
      dispatch(updateCart(response.data.attributes));
      setIsAddingToCart(false);
    } catch (error) {
      setIsAddingToCart(false);
    }
  };

  return { addItemToCart, isAddingToCart };
};

export default useCart;
