import HttpHelpers from './helpers';

const TapApiEndpoints = {
  getTapSdkConfigurations: () => HttpHelpers.authenticatedAxios
    .get('/api/v1/users/payment_card_credentials')
    .then((response) => response.data),
  getTapUser: () => HttpHelpers.authenticatedAxios
    .get('/api/v1/users/me/tap/payment_customer_id')
    .then((response) => response.data),
};

export default TapApiEndpoints;
