import { useTranslation } from 'react-i18next';

interface PaymentBreakdownProps {
  subtotal: number;
  deliveryFees?: number;
  vatPercentage: number;
  vatAmount?: number;
  walletBalance: number;
  discount?: {
    code: string;
    amount: number;
  };
  total: number;
}

export default function PaymentBreakdown({
  subtotal,
  deliveryFees = 0,
  vatPercentage,
  vatAmount = 0,
  walletBalance,
  discount,
  total,
}: PaymentBreakdownProps) {
  const { t } = useTranslation();
  return (
    <dl className='space-y-6 text-sm font-medium text-secondary'>
      <div className='flex justify-between'>
        <dt>
          {' '}
          {t('checkout.orderSummary.subtotal')}
        </dt>
        <dd className='text-black'>
          {subtotal.toFixed(2)}
          {' '}
          {t('shared.currency')}
        </dd>
      </div>
      {deliveryFees > 0 && (
        <div className='flex justify-between'>
          <dt>
            {' '}
            {t('checkout.orderSummary.deliveryFees')}
          </dt>
          <dd className='text-black'>
            {deliveryFees.toFixed(2)}
            {' '}
            {t('shared.currency')}
          </dd>
        </div>
      )}
      <div className='flex justify-between'>
        <dt>
          {t('checkout.orderSummary.vat', { vat: vatPercentage })}
        </dt>
        <dd className='text-black'>
          {vatAmount.toFixed(2)}
          {' '}
          {t('shared.currency')}
        </dd>
      </div>
      {discount?.code && (
        <div className='flex justify-between'>
          <dt className='flex'>
            {t('checkout.orderSummary.discount')}
            <span className='ml-2 rtl:mr-2 rounded-full bg-primary px-2 py-0.5 text-xs tracking-wide text-white'>
              {discount.code}
            </span>
          </dt>
          <dd className='text-black'>
            -
            {discount.amount.toFixed(2)}
            {' '}
            {t('shared.currency')}
          </dd>
        </div>
      )}
      {walletBalance > 0 && (
        <div className='flex justify-between'>
          <dt>
            {t('checkout.orderSummary.walletBalance')}
          </dt>
          <dd className='text-black'>
            -
            {walletBalance.toFixed(2)}
            {' '}
            {t('shared.currency')}
          </dd>
        </div>
      )}
      <div className='flex justify-between border-t border-gray-200 pt-2'>
        {t('checkout.orderSummary.total')}
        <dd className='text-black'>
          {total.toFixed(2)}
          {' '}
          {t('shared.currency')}
        </dd>
      </div>
    </dl>
  );
}
