import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Api from '../services';
import { CartProduct } from '../types/cart';

export const fetchCart = createAsyncThunk('cart/fetchCart', async (cartId: string | null) => {
  if (!cartId) {
    return {
      products: [],
      payment_breakdown: {
        subtotal: 0, vat_percentage: 0, vat_amount: 0, total: 0,
      },
      cart_item_count: 0,
    };
  }
  const response = await Api.cart.fetchCart(cartId);
  localStorage.setItem('cart_id', response.data.attributes.id);
  return response?.data?.attributes;
});

interface CartState {
  products: CartProduct[];
  subtotal: number;
  itemCount: number;
  vatPercentage: number;
  vatAmount: number;
  total: number;
  promoCode: string | null;

}

const initialState: CartState = {
  products: [],
  subtotal: 0,
  itemCount: 0,
  vatPercentage: 0,
  vatAmount: 0,
  total: 0,
  promoCode: null,
};

const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    updateCart: (state, action) => {
      const breakdown = action.payload?.payment_breakdown || {};
      state.products = action.payload.products || [];
      state.subtotal = breakdown?.subtotal || 0;
      state.vatPercentage = breakdown?.vat_percentage || 0;
      state.vatAmount = breakdown?.vat_amount || 0;
      state.total = breakdown?.total || 0;
      state.itemCount = action.payload.cart_item_count || 0;
      localStorage.setItem('cart_id', action.payload.id);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCart.fulfilled, (state, action) => {
      const breakdown = action.payload.payment_breakdown;
      state.products = action.payload.products || [];
      state.subtotal = breakdown.subtotal;
      state.vatPercentage = breakdown.vat_percentage;
      state.vatAmount = breakdown?.vat_amount || 0;
      state.total = breakdown.total;
      state.itemCount = action.payload.cart_item_count || 0;
    });
  },
});

export const { updateCart } = cartSlice.actions;
export default cartSlice.reducer;
