import { StarIcon } from '@heroicons/react/20/solid';
import classNames from 'classnames';

export default function ProductReviews({ reviews }: { reviews: any }) {
  return (
    reviews.featured.map((review: any, reviewIdx: number) => (
      <div key={review.id} className='flex space-x-4 rtl:space-x-reverse text-sm text-secondary'>
        <div className='flex-none py-10'>
          <img alt='' src={review.avatarSrc} className='h-10 w-10 rounded-full bg-gray-100' />
        </div>
        <div className={classNames(reviewIdx === 0 ? '' : 'border-t border-gray-200', 'py-10 w-full')}>
          <div className='flex items-center justify-between'>
            <div>
              <h3 className='font-medium text-secondary'>{review.author}</h3>
              <p>
                <time dateTime={review.datetime}>{review.date}</time>
              </p>
            </div>

            <div className='flex items-center' dir='ltr'>
              {[0, 1, 2, 3, 4].map((rating) => (
                <StarIcon
                  key={rating}
                  aria-hidden='true'
                  className={classNames(
                    review.rating > rating ? 'text-primary' : 'text-gray-300',
                    'h-5 w-5 flex-shrink-0',
                  )}
                />
              ))}
            </div>
          </div>

          <p className='sr-only'>
            {review.rating}
            {' '}
            out of 5 stars
          </p>

          <p className='prose prose-sm mt-4 max-w-none text-secondary'>{review.content}</p>
        </div>
      </div>
    ))
  );
}
