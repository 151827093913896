import { useTranslation } from 'react-i18next';
import { renderPaymentIcon } from '../../../../helpers';

interface PaymentMethodCardProps {
  data: any;
  onDelete: (id: string) => void;
}

export default function PaymentMethodCard({ data, onDelete }: PaymentMethodCardProps) {
  const { t } = useTranslation();

  return (
    <div className='flex justify-between items-center bg-gray-50 shadow-sm rounded-md p-4'>
      <div className='flex flex-col space-y-2'>
        <p className='text-sm font-medium text-black'>{data.attributes.card_number}</p>
        <p className='text-sm text-secondary'>
          {t('account.paymentMethods.savedCards.expires', { date: data.attributes.expiry_date })}
        </p>
      </div>

      <div className='flex flex-col items-center'>
        <div className='flex-shrink-0'>{renderPaymentIcon(data.attributes.brand)}</div>
        <button
          type='button'
          onClick={() => onDelete(data.id)}
          className='text-red-500 hover:text-red-700 text-xs mt-2'
        >
          {t('account.paymentMethods.savedCards.deleteButton')}
        </button>
      </div>
    </div>
  );
}
