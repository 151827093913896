import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { RootState } from '../../redux/store';
import useCartActions from '../../hooks/use-cart-actions';
import useQuote from '../../hooks/use-quote';
import Map from './components/map';
import PhoneForm from '../../views/account/credentials/phone';
import DeliveryProviders from './components/delivery-providers';
import EmptyPlaceholder from '../../components/empty-placholder';
import locationPlaceholder from '../../assets/images/location-placeholder.svg';
import cartPlaceholder from '../../assets/images/empty-cart.svg';
import Api from '../../services';
import Alert from '../../components/alert';
import PaymentMethods from './components/payment-methods';
import OrderSummary from './components/order-summary';
import PaymentIframe from './components/payment-iframe';

export default function Checkout() {
  const { products } = useSelector((state: RootState) => state.cart);
  const profile = useSelector((state: RootState) => state.profile.data);
  const authenticated = useSelector((state: RootState) => state.auth.authenticated);
  const { handleQuantityChange: updateCartQuantity, handleRemoveProduct } = useCartActions();
  const navigate = useNavigate();

  const { t } = useTranslation();
  const [selectedCardId, setSelectedCardId] = useState<string | null>(null);
  const [iframeUrl, setIframeUrl] = useState<string | null>(null);
  const [submitting, setSubmitting] = useState(false);
  const [latitude, setLatitude] = useState<number | null>(null);
  const [longitude, setLongitude] = useState<number | null>(null);
  const [selectedProviderId, setSelectedProviderId] = useState<number | null>(null);
  const [isLocationPermissionEnabled, setIsLocationPermissionEnabled] = useState<boolean>(false);
  const [isErrorVisible, setIsErrorVisible] = useState<boolean>(false);

  const [promoCode, setPromoCode] = useState<string | null>(null);
  const [isInvalidCoupon, setIsInvalidCoupon] = useState<boolean>(false);

  const cartId = localStorage.getItem('cart_id');
  const { quoteData, fetchQuote, loading } = useQuote(cartId);

  const updateQuote = async (providerId: number | null, coupon: string | null = null) => {
    try {
      if (latitude && longitude) {
        await fetchQuote(latitude, longitude, providerId, coupon);
        setPromoCode(coupon);
        setIsInvalidCoupon(false);
      }
    } catch (error: any) {
      if (error.response?.status === 400 && error.response?.data?.error_code === 'invalid_promo_code') {
        setIsInvalidCoupon(true);
        setPromoCode(null);
      } else {
        console.error('Failed to update quote:', error);
      }
    }
  };

  const handleApplyCoupon = async (coupon: string) => {
    await updateQuote(null, coupon);
  };

  const handleRemoveCoupon = async () => {
    setPromoCode(null);
    await updateQuote(selectedProviderId, null);
  };

  const handleQuantityChange = async (productId: number, increment: boolean) => {
    try {
      if (increment) {
        await updateCartQuantity(productId, true);
      } else {
        await updateCartQuantity(productId, false);
      }
      await updateQuote(selectedProviderId, promoCode);
    } catch (error) {
      console.error('Failed to update quantity:', error);
    }
  };

  const handleRemove = async (productId: number) => {
    try {
      await handleRemoveProduct(productId);
      await updateQuote(selectedProviderId, promoCode);
    } catch (error) {
      console.error('Failed to remove item:', error);
    }
  };

  const handleProviderSelect = (providerId: number) => {
    setSelectedProviderId(providerId);
    updateQuote(providerId, promoCode);
  };

  const handleCoordinatesChange = (lat: number, lng: number) => {
    setLatitude(lat);
    setLongitude(lng);
    setSelectedProviderId(null);
    updateQuote(selectedProviderId, promoCode);
  };

  useEffect(() => {
    if (latitude && longitude) {
      updateQuote(selectedProviderId, promoCode);
    }
  }, [latitude, longitude, promoCode]);

  const hasPhoneNumber = profile?.phone_number;

  const handleConfirmOrder = async () => {
    setSubmitting(true);
    setIsErrorVisible(false);
    try {
      const response = await Api.purchases.purchaseCheckout({
        delivery_lat: latitude,
        delivery_lon: longitude,
        payment_method: 'card',
        payment_card_id: selectedCardId,
        include_delivery: true,
        ecommerce_purchase: true,
        cart_id: cartId,
        signature: quoteData?.signature,
        delivery_provider_id: selectedProviderId,
        promo_code: isInvalidCoupon ? null : promoCode,
      });
      if (response.payment.card_3ds_redirect_url) {
        setIframeUrl(response.payment.card_3ds_redirect_url);
      } else {
        navigate(`/checkout/${response.id}`);
      }
    } catch (error) {
      setIsErrorVisible(true);
    } finally {
      setSubmitting(false);
    }
  };

  const handleIframeMessage = (event: MessageEvent) => {
    if (event.data?.purchaseStatus === 'successful') {
      setIframeUrl(null);
      navigate(`/checkout/${event.data?.purchaseId}`);
    } else if (event.data?.purchaseStatus === 'failed') {
      setIframeUrl(null);
      navigate(`/checkout/${event.data?.purchaseId}`);
    }
  };

  useEffect(() => {
    window.addEventListener('message', handleIframeMessage);
    return () => {
      window.removeEventListener('message', handleIframeMessage);
    };
  }, []);

  const requestLocationPermission = async () => {
    if (!navigator.geolocation) {
      setIsLocationPermissionEnabled(false);
      return;
    }

    navigator.geolocation.getCurrentPosition(
      (position) => {
        setLatitude(position.coords.latitude);
        setLongitude(position.coords.longitude);
        setIsLocationPermissionEnabled(true);
      },
      (error) => {
        if (error.code === error.PERMISSION_DENIED) {
          setIsLocationPermissionEnabled(false);
        }
      },
    );
  };

  useEffect(() => {
    requestLocationPermission();
  }, []);

  if (!isLocationPermissionEnabled) {
    return (
      <EmptyPlaceholder
        title={t('checkout.locationPermission.title')}
        subtitle={t('checkout.locationPermission.subtitle')}
        imageSrc={locationPlaceholder}
      />
    );
  }

  if (products?.length === 0) {
    return (
      <EmptyPlaceholder
        title={t('cart.emptyTitle')}
        subtitle={t('cart.emptySubtitle')}
        imageSrc={cartPlaceholder}
      />
    );
  }
  return (
    <div>
      <div className='mx-auto max-w-2xl px-4 pb-24 lg:px-8 lg:py-12 sm:px-6 lg:max-w-7xl lg:px-0'>
        <h2 className='sr-only'>Checkout</h2>
        <div className='lg:grid lg:grid-cols-2 lg:gap-x-12 xl:gap-x-16'>
          <div className='lg:order-1'>
            {authenticated && !hasPhoneNumber && (
              <div className='border-b border-gray-200 pb-6 mb-4'>
                <h2 className='text-lg font-medium text-black mb-4'>
                  أضف رقم الجوال لتتمكن من استلام طلبك
                </h2>
                <PhoneForm />
              </div>
            )}
            <Map
              onCoordinatesChange={handleCoordinatesChange}
              address={quoteData?.delivery?.address}
            />
            <DeliveryProviders
              providers={quoteData?.delivery?.delivery_providers || []}
              selectedProviderId={selectedProviderId}
              onSelect={handleProviderSelect}
              loading={loading}
            />
            <PaymentMethods
              setSelectedCardId={setSelectedCardId}
              selectedCardId={selectedCardId}
            />
          </div>
          <OrderSummary
            handleQuantityChange={handleQuantityChange}
            handleRemove={handleRemove}
            quoteData={quoteData}
            confirmOrder={handleConfirmOrder}
            loading={loading}
            disableSubmit={!hasPhoneNumber || !selectedCardId || !selectedProviderId || submitting}
            handleApplyCoupon={handleApplyCoupon}
            promoCode={promoCode}
            isInvalidCoupon={isInvalidCoupon}
            handleRemoveCoupon={handleRemoveCoupon}
          />
          {' '}

        </div>
      </div>
      <PaymentIframe
        iframeUrl={iframeUrl}
        setIframeUrl={setIframeUrl}
      />
      <Alert
        title={t('purchaseStatus.failed.title')}
        text={t('purchaseStatus.failed.subtitle')}
        isAlertVisible={isErrorVisible}
        onClose={() => setIsErrorVisible(false)}
        type='error'
      />
    </div>
  );
}
