const delivery = [
  'التوصيل السريع: إذا كنت داخل مدينة الرياض فيصلك الباور بانك خلال 3 ساعات من عملية الشراء.',
  'الضمان وخدمة الاسترجاع: نوفر ضمانًا كاملاً على المنتج بالإضافة إلى خدمة الاسترجاع.',
  'الدفع المباشر والتقسيط: ندعم جميع شبكات الدفع الإلكتروني بالإضافة إلى خدمة التقسيط (تابي).',
];

export default function Delivery() {
  return (
    <div className='prose prose-sm mt-3 text-secondary pt-6'>
      <ul>
        {delivery.map((d) => (
          <li key={d}>{d}</li>
        ))}
      </ul>
    </div>
  );
}
