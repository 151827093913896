/* eslint-disable react/no-array-index-key */
import { StarIcon } from '@heroicons/react/20/solid';
import { AnimatePresence, motion } from 'framer-motion';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

interface AddReviewModalProps {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
}

export default function AddReviewModal({ isOpen, setIsOpen }: AddReviewModalProps) {
  const { t } = useTranslation();
  const [rating, setRating] = useState(0);

  const handleRating = (index: number) => {
    setRating(index);
  };
  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          onClick={() => setIsOpen(false)}
          className='bg-slate-900/20 backdrop-blur p-8 fixed inset-0 z-50 grid place-items-center overflow-y-scroll cursor-pointer'
        >
          <motion.div
            initial={{ scale: 0, rotate: '12.5deg' }}
            animate={{ scale: 1, rotate: '0deg' }}
            exit={{ scale: 0, rotate: '0deg' }}
            onClick={(e) => e.stopPropagation()}
            className='bg-white p-6 rounded-lg w-full max-w-lg shadow-xl cursor-default relative overflow-hidden'
          >
            <h3 className='text-2xl font-bold text-center mb-2 text-primary'>
              {t('productDetails.reviews.addReview')}
            </h3>
            <p className='text-center mb-3 text-black'>
              {' '}
              {t('productDetails.reviews.reviewPrompt')}
            </p>
            <div className='flex justify-center space-x-2' dir='ltr'>
              {[...Array(5)].map((_, index) => (
                <StarIcon
                  key={index}
                  onClick={() => handleRating(index + 1)}
                  className={`h-6 w-6 cursor-pointer ${
                  index < rating ? 'text-primary' : 'text-primary/30'}`}
                />
              ))}
            </div>
            <label htmlFor='message' className='block text-sm font-medium leading-6 text-black mb-2'>{t('productDetails.reviews.comment')}</label>
            <textarea
              id='message'
              rows={4}
              className='block w-full rounded-md border-0 py-2.5 text-black shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-primary disabled:bg-zinc-100 sm:text-sm sm:leading-6'
              placeholder={t('productDetails.reviews.commentPlaceholder')}
            />
            <div className='flex gap-2 mt-6'>
              <button
                type='button'
                onClick={() => setIsOpen(false)}
                className='bg-transparent text-primary hover:bg-white/10 transition-colors font-semibold w-full py-2 rounded'
              >
                {t('productDetails.reviews.cancel')}
              </button>
              <button
                type='button'
                onClick={() => setIsOpen(false)}
                className='bg-primary hover:opacity-90 transition-opacity text-white font-semibold w-full py-2 rounded'
              >
                {t('productDetails.reviews.submit')}
              </button>
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
}
