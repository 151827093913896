import { StarIcon } from '@heroicons/react/20/solid';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

interface ProductReviewsProps {
  reviews: any;
  setIsReviewModalOpen: (value: boolean) => void;
}

export default function ReviewsStatistics({ reviews, setIsReviewModalOpen }: ProductReviewsProps) {
  const { t } = useTranslation();

  return (
    <div className='order-1 lg:order-1'>
      <h2 className='text-2xl font-bold tracking-tight text-black'>{t('productDetails.reviews.productReviews')}</h2>

      <div className='mt-6' dir='ltr'>
        <h3 className='sr-only'>Review data</h3>

        <dl className='space-y-3'>
          {reviews.stats.map((stat: any) => (
            <div key={stat.rating} className='flex items-center text-sm'>
              <dt className='flex flex-1 items-center'>
                <p className='w-3 font-medium text-secondary'>
                  {stat.rating}
                  <span className='sr-only'> star reviews</span>
                </p>
                <div aria-hidden='true' className='ml-1 flex flex-1 items-center'>
                  <StarIcon
                    aria-hidden='true'
                    className={classNames(
                      stat.count > 0 ? 'text-primary' : 'text-gray-300',
                      'h-5 w-5 flex-shrink-0',
                    )}
                  />

                  <div className='relative ml-3 flex-1'>
                    <div className='h-3 rounded-full border border-gray-200 bg-gray-100' />
                    {stat.count > 0 ? (
                      <div
                        style={{ width: `calc(${stat.count} / ${reviews.totalCount} * 100%)` }}
                        className='absolute inset-y-0 rounded-full border border-primary bg-primary'
                      />
                    ) : null}
                  </div>
                </div>
              </dt>
              <dd className='ml-3 w-10 text-right text-sm tabular-nums text-secondary'>
                {Math.round((stat.count / reviews.totalCount) * 100)}
                %
              </dd>
            </div>
          ))}
        </dl>
      </div>

      <div className='mt-10'>
        <h3 className='text-lg font-medium text-black'>{t('productDetails.reviews.addReview')}</h3>
        <p className='mt-1 text-sm text-secondary'>
          {t('productDetails.reviews.reviewPrompt')}
        </p>
        <button
          onClick={() => setIsReviewModalOpen(true)}
          type='button'
          className='mt-6 inline-flex w-full items-center justify-center rounded-md border border-transparent bg-primary px-8 py-3 h-11 text-base font-medium text-white shadow-sm hover:bg-lime-600 disabled:bg-lime-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-lime-600 sm:w-full'
        >
          {t('productDetails.reviews.addReviewBtn')}
        </button>
      </div>
    </div>
  );
}
