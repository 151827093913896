import { combineReducers } from '@reduxjs/toolkit';
import auth from './auth';
import profile from './profile';
import cart from './cart-slice';

const rootReducer = combineReducers({
  auth,
  profile,
  cart,
});

export default rootReducer;
