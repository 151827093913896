import { useEffect, useState } from 'react';
import Api from '../services';

const usePaymentMethods = () => {
  const [paymentMethods, setPaymentMethods] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [tapUserDetails, setTapUserDetails] = useState<any>(null);

  const fetchPaymentMethods = async () => {
    try {
      const response = await Api.paymentMethods.getCards();
      setPaymentMethods(response.data || []);
    } catch {
      console.error('Failed to fetch saved cards');
    } finally {
      setLoading(false);
    }
  };

  const deletePaymentMethod = async (paymentMethodId: string) => {
    try {
      await Api.paymentMethods.deleteCard(paymentMethodId);
      setPaymentMethods((prevMethods) => prevMethods.filter((method) => method.id !== paymentMethodId));
    } catch {
      console.error('Failed to delete payment method');
    }
  };

  const fetchTapConfigs = async () => {
    try {
      const userDetails = await Api.tap.getTapUser();
      setTapUserDetails({
        id: userDetails.data?.attributes?.tap_customer_id,
        first_name: userDetails.data?.attributes?.full_name,
        email: userDetails.data?.attributes?.tap_email,
        phone: {
          country_code: userDetails.data?.attributes?.payment_mobile.country_code,
          number: userDetails.data?.attributes?.payment_mobile.number,
        },
      });
    } catch (err) {
      console.error('Failed to load Tap user details', err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPaymentMethods();
    fetchTapConfigs();
  }, []);

  return {
    fetchPaymentMethods, loading, paymentMethods, fetchTapConfigs, tapUserDetails, deletePaymentMethod,
  };
};

export default usePaymentMethods;
