import { UserCircleIcon, KeyIcon, CreditCardIcon } from '@heroicons/react/24/outline';
import { Outlet, NavLink, useParams } from 'react-router-dom';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

export default function Account() {
  const { locale } = useParams();
  const { t } = useTranslation();

  const subNavigation = [
    {
      name: t('account.update.title'), href: `/${locale}/account/update`, icon: UserCircleIcon,
    },
    {
      name: t('account.credentials.title'), href: `/${locale}/account/credentials`, icon: KeyIcon,
    },
    {
      name: t('account.paymentMethods.title'),
      href: `/${locale}/account/payment-methods`,
      icon: CreditCardIcon,
    },
  ];
  return (
    <div>
      <main className='mx-auto max-w-7xl pb-10 lg:px-8 lg:py-12'>
        <div className='lg:grid lg:grid-cols-12 lg:gap-x-5'>
          <aside className='px-2 py-6 sm:px-6 lg:col-span-3 lg:px-0 lg:py-0'>
            <nav className='space-y-1'>
              {subNavigation.map((item) => (
                <NavLink
                  key={item.name}
                  to={item.href}
                  className={({ isActive }) => classNames(
                    isActive
                      ? 'bg-white text-primary hover:bg-white'
                      : 'text-secondary hover:bg-white hover:text-primary',
                    'group flex items-center rounded-md px-3 py-2 text-sm font-medium',
                  )}

                >
                  {({ isActive }) => (
                    <>
                      <item.icon
                        className={classNames(
                          isActive ? 'text-primary' : 'text-secondary group-hover:text-primary',
                          '-mr-1 rtl:ml-3 mr-3 h-6 w-6 flex-shrink-0',
                        )}
                      />
                      <span className='truncate'>{item.name}</span>
                    </>
                  )}
                </NavLink>
              ))}
            </nav>
          </aside>
          <Outlet />
        </div>
      </main>
    </div>
  );
}
