import React, { useState, useRef } from 'react';
import { PlayIcon } from '@heroicons/react/24/solid';

function VideoWithOverlay({ media }: { media: any }) {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [isPlaying, setIsPlaying] = useState(false);

  // Handle video play/pause
  const handleVideoClick = () => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause();
      } else {
        videoRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  const handlePause = () => {
    setIsPlaying(false);
  };

  const handlePlay = () => {
    setIsPlaying(true);
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Space' || event.key === ' ') {
      handleVideoClick();
    }
  };

  return (
    <>
      <video
        ref={videoRef}
        controls
        controlsList='nodownload noplaybackrate'
        autoPlay
        muted
        disablePictureInPicture
        onPlay={handlePlay}
        onPause={handlePause}
        className='h-full w-full object-cover object-center rounded-lg'
      >
        <source src={media.url} type={media.content_type} />
        Your browser does not support the video tag.
      </video>

      {!isPlaying && (
        <button
          type='button'
          className='absolute inset-0 flex items-center justify-center bg-black bg-opacity-30 cursor-pointer'
          onClick={handleVideoClick}
          onKeyDown={handleKeyDown}
          aria-label='Play video'
          tabIndex={0}
        >
          <PlayIcon className='w-16 h-16 text-white opacity-80' />
        </button>
      )}
    </>
  );
}
export default VideoWithOverlay;
