import { RouterProvider } from 'react-router-dom';
import { useEffect } from 'react';
import router from './router';

function App() {
  useEffect(() => {
    let storedCartId = localStorage.getItem('cart_id');
    if (!storedCartId) {
      storedCartId = crypto.randomUUID();
      localStorage.setItem('cart_id', storedCartId);
    }
  }, []);
  return (
    <RouterProvider router={router} />
  );
}

export default App;
