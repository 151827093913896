import AccountsApiEndpoints from './accounts.api';
import OidcApiEndpoints from './oidc.api';
import * as helpers from './helpers';
import LookupsApiEndpoints from './lookups.api';
import ItemsApiEndpoints from './items.api';
import CompaniesApiEndpoints from './companies.api';
import RetailProductsApiEndpoints from './retail_products.api';
import CartApiEndpoints from './cart.api';
import PaymentMethodsApiEndpoints from './payment-methods.api';
import DeliveryRequestsApiEndpoints from './delivery-requests.api';
import PurcahsesApiEndpoints from './purchase.api';
import TapApiEndpoints from './tap.api';

const Api = {
  ...helpers,
  accounts: AccountsApiEndpoints,
  oidc: OidcApiEndpoints,
  lookups: LookupsApiEndpoints,
  items: ItemsApiEndpoints,
  companies: CompaniesApiEndpoints,
  retailProducts: RetailProductsApiEndpoints,
  cart: CartApiEndpoints,
  paymentMethods: PaymentMethodsApiEndpoints,
  deliveryRequests: DeliveryRequestsApiEndpoints,
  purchases: PurcahsesApiEndpoints,
  tap: TapApiEndpoints,
};

export default Api;
