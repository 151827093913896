import classNames from 'classnames';

interface EmptyPlaceholderProps {
  title: string;
  subtitle: string;
  imageSrc: string;
  fullHeight?: boolean;
}

export default function EmptyPlaceholder({
  title,
  subtitle,
  imageSrc,
  fullHeight = true,
}: EmptyPlaceholderProps) {
  return (
    <div
      className={classNames(
        'flex flex-col items-center justify-center text-center',
        { 'h-full': fullHeight },
      )}
    >
      <div className='flex items-center justify-center'>
        <img
          src={imageSrc}
          alt={title}
          className='w-[150px] h-[150px] object-contain'
        />
      </div>
      <h3 className='mt-2 text-sm font-semibold text-gray-900'>{title}</h3>
      <p className='mt-1 text-sm text-secondary'>{subtitle}</p>
    </div>
  );
}
