import React from 'react';

export default function ProductDetailsSkeleton() {
  return (
    <div className='bg-white py-24 sm:py-10'>
      <div className='mx-auto max-w-2xl px-4 sm:px-6 lg:max-w-7xl lg:px-0'>
        <div className='lg:grid lg:grid-cols-2 lg:items-start lg:gap-x-8'>
          {/* Product media shimmer */}
          <div className='lg:order-2 rtl:lg:order-1'>
            <div className='animate-pulse h-96 w-full bg-gray-200 rounded-lg' />
          </div>
          {/* Product info shimmer */}
          <div className='mt-10 sm:mt-16 sm:px-0 lg:mt-0 lg:order-1 rtl:lg:order-2'>
            <div className='h-8 w-48 bg-gray-200 rounded' />
            <div className='flex items-center mt-3 space-x-4'>
              <div className='h-6 w-24 bg-gray-200 rounded' />
              <div className='h-6 w-32 bg-gray-200 rounded' />
            </div>
            <div className='mt-6 h-4 w-full bg-gray-200 rounded' />
            <div className='mt-2 h-4 w-4/5 bg-gray-200 rounded' />
            <div className='mt-2 h-4 w-3/4 bg-gray-200 rounded' />
            <div className='mt-6 flex space-x-4'>
              <div className='h-11 w-32 bg-gray-200 rounded' />
              <div className='flex items-center space-x-2'>
                <div className='h-11 w-11 bg-gray-200 rounded' />
                <div className='h-11 w-11 bg-gray-200 rounded' />
                <div className='h-11 w-11 bg-gray-200 rounded' />
              </div>
            </div>
            <div className='mt-10 border-t border-gray-200 pt-8'>
              <div className='h-6 w-40 bg-gray-200 rounded' />
              <div className='mt-3 space-y-2'>
                <div className='h-4 w-full bg-gray-200 rounded' />
                <div className='h-4 w-4/5 bg-gray-200 rounded' />
                <div className='h-4 w-3/4 bg-gray-200 rounded' />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
