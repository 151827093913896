import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import EmptyPlaceholder from '../../../components/empty-placholder';
import usePaymentMethods from '../../../hooks/use-payment-methods';
import emptyCardsPlaceholder from '../../../assets/images/empty-cards.svg';
import PaymentMethodCard from './payment-method-card';

interface PaymentMethodsProps {
  setSelectedCardId: (id: string | null) => void;
  selectedCardId: string | null;
}

export default function PaymentMethods({
  setSelectedCardId,
  selectedCardId,
}: PaymentMethodsProps) {
  const {
    fetchPaymentMethods, loading, paymentMethods,
  } = usePaymentMethods();

  const { t } = useTranslation();
  useEffect(() => {
    fetchPaymentMethods();
  }, []);

  if (loading) {
    return (
      <div className='grid grid-cols-1 gap-4'>
        {[...Array(2)].map((item) => (
          <div key={item} className='w-full h-20 bg-gray-300 rounded-md animate-pulse' />
        ))}
      </div>
    );
  }
  if (paymentMethods?.length === 0) {
    return (
      <>
        <h2 className='text-lg font-medium text-black mb-4'>{t('account.paymentMethods.title')}</h2>
        <EmptyPlaceholder
          title={t('account.paymentMethods.savedCards.noCardsMessage')}
          subtitle={t('account.paymentMethods.addCard')}
          imageSrc={emptyCardsPlaceholder}
          fullHeight={false}
        />
      </>
    );
  }
  return (
    <div className='pb-6'>
      <h2 className='text-lg font-medium text-black mt-4 mb-4'>{t('account.paymentMethods.title')}</h2>
      <div className='grid grid-cols-1 gap-4'>
        {paymentMethods.map((paymentMethod) => (
          <PaymentMethodCard
            key={paymentMethod.id}
            data={paymentMethod}
            onSelect={() => setSelectedCardId(paymentMethod.id)}
            selectedCardId={selectedCardId}
          />
        ))}
      </div>
    </div>
  );
}
