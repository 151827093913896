import axios, { AxiosInstance } from 'axios';
import QueryString from 'qs';
import env from '../env';
import i18n from '../i18n';

class HttpHelpers {
  apiBaseUrl: string;

  authenticatedAxios: AxiosInstance;

  unAuthenticatedAxios: AxiosInstance;

  subscribers: any;

  isAlreadyFetchingAccessToken: boolean;

  constructor() {
    this.subscribers = [];
    this.isAlreadyFetchingAccessToken = false;
    this.apiBaseUrl = env.REACT_APP_API_URL;
    this.authenticatedAxios = axios.create({ baseURL: this.apiBaseUrl });
    this.unAuthenticatedAxios = axios.create({ baseURL: this.apiBaseUrl });
    this.addAuthenticatedInterceptor();
    this.addUnauthenticatedInterceptor();
    this.addRefreshTokenInterceptor();
  }

  static getToken(): string | null {
    return localStorage.getItem('access_token');
  }

  static getRefreshToken() {
    return localStorage.getItem('refresh_token');
  }

  async refreshToken() {
    return axios({
      method: 'post',
      url: `${this.apiBaseUrl}/connect/token`,
      data: QueryString.stringify({
        client_id: 'chaizer-webstore',
        client_secret: 'chaizer',
        grant_type: 'refresh_token',
        refresh_token: HttpHelpers.getRefreshToken(),
      }),
      headers: {
        'content-type': 'application/x-www-form-urlencoded;charset=utf-8',
      },
    });
  }

  static setToken(value: string) {
    localStorage.setItem('access_token', value);
  }

  static setRefreshToken(value: string) {
    localStorage.setItem('refresh_token', value);
  }

  onAccessTokenFetched(accessToken: any) {
    this.subscribers = this.subscribers.filter((callback: (arg0: any) => any) => callback(accessToken));
  }

  addSubscriber(callback: (accessToken: any) => void) {
    this.subscribers.push(callback);
  }

  addRefreshTokenInterceptor() {
    this.authenticatedAxios.interceptors.response.use(
      (response) => response,
      (error) => {
        const { config, response } = error;
        const originalRequest = config;

        if (response && response.status === 401) {
          if (!this.isAlreadyFetchingAccessToken) {
            this.isAlreadyFetchingAccessToken = true;
            this.refreshToken().then((response) => {
              this.isAlreadyFetchingAccessToken = false;

              HttpHelpers.setToken(response.data.access_token);
              HttpHelpers.setRefreshToken(response.data.refresh_token);

              this.onAccessTokenFetched(response.data.access_token);
            }).catch(async (error) => {
              if (error.response.status === 400) {
                localStorage.removeItem('access_token');
                localStorage.removeItem('refresh_token');
              }
            });
          }
          const retryOriginalRequest = new Promise((resolve) => {
            this.addSubscriber((accessToken: any) => {
              originalRequest.headers.Authorization = `Bearer ${accessToken}`;
              resolve(axios(originalRequest));
            });
          });
          return retryOriginalRequest;
        }
        return Promise.reject(error);
      },
    );
  }

  addAuthenticatedInterceptor(): void {
    this.authenticatedAxios.interceptors.request.use(
      async (config) => {
        const accessToken = HttpHelpers.getToken();
        const locale = i18n.language || 'ar';
        if (accessToken && config.headers) {
          config.headers.Authorization = `Bearer ${accessToken}`;
        }
        if (config.headers) {
          config.headers['Accept-Language'] = locale;
        }
        return config;
      },
      (error) => Promise.reject(error),
    );
  }

  addUnauthenticatedInterceptor(): void {
    this.unAuthenticatedAxios.interceptors.request.use(
      (config) => {
        const locale = i18n.language || 'ar';
        if (config.headers) {
          config.headers['Accept-Language'] = locale;
        }
        return config;
      },
      (error) => Promise.reject(error),
    );
  }
}

export default new HttpHelpers();
