import { ReactElement, useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';

interface RequireAuthProps {
  children: ReactElement | null;
}

function RequireAuth({ children }: RequireAuthProps) {
  const navigate = useNavigate();
  const location = useLocation();
  const { locale } = useParams();
  const authenticated = useSelector((state: RootState) => state.auth.authenticated);

  useEffect(() => {
    if (!authenticated) {
      const desiredPath = (location.pathname.startsWith(`/${locale}/activation`) || location.pathname.startsWith(`/${locale}/checkout`)) ? `/${locale}/auth/login` : `/${locale}`;

      if (location.pathname !== desiredPath) {
        navigate(desiredPath, { state: { from: location } });
      }
    }
  }, [authenticated, navigate, location, locale]);

  return authenticated ? children : null;
}

export default RequireAuth;
