import { useState } from 'react';

interface PromoCodeFormProps {
  onApply: (coupon: string) => void;
  onRemove: () => void;
  currentPromoCode?: string | null;
  isInvalid?: boolean;
}

export default function PromoCodeForm({
  onApply,
  onRemove,
  currentPromoCode,
  isInvalid,
}: PromoCodeFormProps) {
  const [coupon, setCoupon] = useState('');

  const handleSubmit = () => {
    if (coupon.trim()) {
      onApply(coupon);
    }
    setCoupon('');
  };

  return (
    <div className='flex flex-col space-y-3'>
      {/* If there's no current promo code, show the input */}
      {!currentPromoCode && (
        <>
          <div className='flex items-center space-x-2'>
            <input
              type='text'
              placeholder='Enter Promo Code'
              value={coupon}
              onChange={(e) => setCoupon(e.target.value)}
              className={`w-full p-2 border rounded-md text-sm ${
                isInvalid ? 'border-red-500' : 'border-gray-300'
              } focus:ring-primary focus:outline-none`}
            />
            <button
              type='button'
              onClick={handleSubmit}
              className='px-4 py-2 bg-primary text-white rounded-md hover:bg-lime-600 transition'
            >
              Apply
            </button>
          </div>
          {/* Show error message if the promo code is invalid */}
          {isInvalid && (
            <p className='text-red-500 text-sm'>
              Invalid or expired promo code. Please try again.
            </p>
          )}
        </>
      )}

      {/* If there's a valid promo code, show the "Remove" section */}
      {currentPromoCode && (
        <div className='flex items-center justify-between p-2 bg-gray-100 rounded-md'>
          <span className='text-sm font-medium text-primary'>
            Promo Code:
            {' '}
            {currentPromoCode}
          </span>
          <button
            type='button'
            onClick={onRemove}
            className='text-red-500 text-sm hover:underline'
          >
            Remove
          </button>
        </div>
      )}
    </div>
  );
}
