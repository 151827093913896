import {
  useState, useEffect, useRef, useCallback,
} from 'react';
import {
  GoogleMap,
  useLoadScript,
  Marker,
  Autocomplete,
} from '@react-google-maps/api';
import { useTranslation } from 'react-i18next';
import env from '../../../env';
import markerIcon from '../../../assets/images/marker.svg';

// Google Map configuration options
const mapOptions: google.maps.MapOptions = {
  disableDefaultUI: true,
  zoomControl: true,
};

type Coordinates = { lat: number; lng: number };

interface MapProps {
  onCoordinatesChange: (lat: number, lng: number) => void;
  address?: string;
}

function Map({ onCoordinatesChange, address }: MapProps) {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries: ['places'],
  });

  const mapRef = useRef<google.maps.Map | null>(null);
  const autocompleteRef = useRef<google.maps.places.Autocomplete | null>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);

  const [center, setCenter] = useState<Coordinates | null>(null);
  const { t } = useTranslation();

  useEffect(() => {
    if (!inputRef.current) {
      return;
    }

    inputRef.current.value = address ?? '';
  }, [address]);

  /**
   * Fetch user's current location and set as the initial center.
   */
  useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const userLocation: Coordinates = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
        setCenter(userLocation);
        onCoordinatesChange(userLocation.lat, userLocation.lng);
      },
      (error) => {
        console.warn('Failed to get user location:', error.message);
      },
    );
  }, []);

  /**
   * Handle map drag end event and update center coordinates.
   */
  const handleDragEnd = useCallback(() => {
    const newCenter = mapRef.current?.getCenter();
    if (newCenter) {
      const latLng: Coordinates = {
        lat: newCenter.lat(),
        lng: newCenter.lng(),
      };
      setCenter(latLng);
      onCoordinatesChange(latLng.lat, latLng.lng);
    }
  }, []);

  /**
   * Handle autocomplete selection and update the map center.
   */
  const handlePlaceChanged = useCallback(() => {
    const place = autocompleteRef.current?.getPlace();
    if (place?.geometry?.location) {
      const location: Coordinates = {
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      };
      setCenter(location);
      onCoordinatesChange(location.lat, location.lng);
    }
  }, []);

  if (!isLoaded || !center) {
    return (
      <div className='border-b border-gray-200 pb-6 mb-4'>
        <h2 className='text-lg font-medium text-black mb-4'>{t('checkout.deliveryAddress')}</h2>
        <div className='relative mt-4'>
          <div className='mt-4 animate-pulse bg-gray-300 rounded h-96 w-full' />
        </div>
      </div>
    );
  }

  return (
    <div className='border-b border-gray-200 pb-6 mb-4'>
      <h2 className='text-lg font-medium text-black mb-4'>{t('checkout.deliveryAddress')}</h2>
      <div className='relative mt-4'>
        {/* Autocomplete Search Input */}
        <div className='w-11/12 absolute top-4 left-1/2 transform -translate-x-1/2 z-10'>
          <Autocomplete
            onLoad={(autocomplete) => {
              autocompleteRef.current = autocomplete;
            }}
            onPlaceChanged={handlePlaceChanged}
            options={{
              componentRestrictions: { country: 'SA' },
            }}
          >
            <input
              type='text'
              placeholder='Search places...'
              className='w-full rounded-md border-0 py-2.5 text-black shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-primary disabled:bg-zinc-100 sm:text-sm sm:leading-6'
              ref={inputRef}
            />
          </Autocomplete>
        </div>
        {/* Google Map */}
        <GoogleMap
          id='map'
          mapContainerClassName='h-96 w-full rounded-md outline-none'
          zoom={16}
          center={center}
          options={mapOptions}
          onLoad={(map) => {
            mapRef.current = map;
          }}
          onDragEnd={handleDragEnd}
        >
          {/* Marker for the center location */}
          {center && (
            <Marker
              position={center}
              icon={{
                url: markerIcon,
                scaledSize: new google.maps.Size(40, 40),
              }}
            />
          )}
        </GoogleMap>
      </div>
    </div>
  );
}

export default Map;
