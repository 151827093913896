import React from 'react';
import masterCardIcon from '../assets/images/mastercard.svg';
import visaIcon from '../assets/images/visa.svg';
import madaIcon from '../assets/images/mada.svg';

export const getErrorsByPath = (errors: Record<string, any>, name: string) => {
  const parts = name.split('.');

  return parts.reduce((currentErrors, part) => {
    if (!currentErrors) return undefined;
    return currentErrors[part];
  }, errors);
};

export const setServerErrors = (responseErrors: any, setError: any) => {
  for (const key of Object.keys(responseErrors)) {
    const message = responseErrors[key][0];
    setError(key, { message, type: 'manual' });
  }
};

export const renderGender = (gender: string | null | undefined): 'M' | 'F' | undefined => {
  switch (gender) {
  case 'male':
    return 'M';
  case 'female':
    return 'F';
  default:
    return undefined;
  }
};

export const renderPaymentIcon = (brand: string) => {
  switch (brand) {
  case 'MASTERCARD':
    return <img src={masterCardIcon} alt={brand} className='w-15 h-15 mr-4 rtl:mr-0 rtl:ml-4' />;

  case 'VISA':
    return <img src={visaIcon} alt={brand} className='w-15 h-15 mr-4 rtl:mr-0 rtl:ml-4' />;

  case 'MADA':
    return <img src={madaIcon} alt={brand} className='w-15 h-15 mr-4 rtl:mr-0 rtl:ml-4' />;

  default:
    return (
      <img src={masterCardIcon} alt={brand} className='w-15 h-15 mr-4 rtl:mr-0 rtl:ml-4' />
    );
  }
};
