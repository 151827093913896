import { useEffect, useRef } from 'react';
import {
  Dialog, DialogBackdrop, DialogPanel, DialogTitle,
} from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { RootState } from '../../redux/store';
import useCartActions from '../../hooks/use-cart-actions';
import ProductCard from '../../components/product-card';
import DEFAULT_IMAGE from '../../assets/images/default-product.jpg';
import emptyCartPlaceholder from '../../assets/images/empty-cart.svg';
import { CartProduct } from '../../types/cart';
import PaymentBreakdown from './components/payment-breakdown';
import EmptyPlaceholder from '../../components/empty-placholder';

export default function Cart({ isOpen, toggleDrawer }: { isOpen: boolean, toggleDrawer: (value: boolean) => void }) {
  const {
    products, subtotal, vatPercentage, vatAmount, total,
  } = useSelector((state: RootState) => state.cart);
  const { getCart, handleQuantityChange, handleRemoveProduct } = useCartActions();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const hasFetched = useRef(false);
  const { locale } = useParams();

  useEffect(() => {
    if (isOpen && !hasFetched.current) {
      getCart();
      hasFetched.current = true;
    }

    return () => {
      if (!isOpen) {
        hasFetched.current = false;
      }
    };
  }, [isOpen, getCart]);

  const confirmOrder = () => {
    toggleDrawer(false);
    navigate(`/${locale}/checkout`);
  };
  return (
    <Dialog open={isOpen} onClose={() => toggleDrawer(true)} className='relative z-10'>
      <DialogBackdrop
        transition
        className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity duration-500 ease-in-out data-[closed]:opacity-0'
      />
      <div className='fixed inset-0 overflow-hidden'>
        <div className='absolute inset-0 overflow-hidden'>
          <div className='pointer-events-none fixed inset-y-0 ltr:right-0 rtl:left-0 flex max-w-full ltr:pl-10 rtl:pr-10'>
            <DialogPanel
              transition
              className='pointer-events-auto w-screen max-w-md transform transition duration-500 ease-in-out data-[closed]:ltr:translate-x-full data-[closed]:rtl:-translate-x-full sm:duration-700'
            >
              <div className='flex h-full flex-col overflow-y-scroll no-scrollbar bg-white shadow-xl'>
                <div className='flex-1 overflow-y-auto px-4 py-6 sm:px-6'>
                  <div
                    className={classNames(
                      'flex items-start justify-between',
                      { 'mb-6': products?.length > 0 },
                    )}
                  >
                    <DialogTitle className='text-lg font-medium text-black'>{t('cart.cartItems')}</DialogTitle>
                    <div className='ltr:ml-3 rtl:mr-3 flex h-7 items-center'>
                      <button
                        type='button'
                        onClick={() => toggleDrawer(false)}
                        className='relative -m-2 p-2 text-gray-400 hover:text-gray-500'
                      >
                        <span className='absolute -inset-0.5' />
                        <span className='sr-only'>Close panel</span>
                        <XMarkIcon aria-hidden='true' className='h-6 w-6' />
                      </button>
                    </div>
                  </div>
                  <div className='h-full'>
                    {products?.length === 0 ? (
                      <EmptyPlaceholder
                        title={t('cart.emptyTitle')}
                        subtitle={t('cart.emptySubtitle')}
                        imageSrc={emptyCartPlaceholder}
                      />
                    ) : products.length > 0 && (
                      <div className='flow-root'>
                        <ul className='-my-6 divide-y divide-gray-200'>
                          {products.map((product: CartProduct) => (
                            <ProductCard
                              key={product.id}
                              data={product}
                              logo={product.logo?.url || DEFAULT_IMAGE}
                              handleDecrement={() => handleQuantityChange(product.id, false)}
                              handleIncrement={() => handleQuantityChange(product.id, true)}
                              handleRemove={() => handleRemoveProduct(product.id)}
                            />
                          ))}
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
                {products.length > 0 && (
                  <div className='border-t border-gray-200 px-4 py-6 sm:px-6'>
                    <PaymentBreakdown
                      subtotal={subtotal}
                      vatPercentage={vatPercentage}
                      vatAmount={vatAmount}
                      total={total}
                    />
                    <button
                      type='button'
                      onClick={confirmOrder}
                      className='mt-6 w-full flex items-center justify-center rounded-md border border-transparent bg-primary px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-lime-600'
                    >
                      {t('cart.checkout')}
                    </button>
                  </div>
                )}
              </div>
            </DialogPanel>
          </div>
        </div>
      </div>
    </Dialog>
  );
}
