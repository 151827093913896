import { Product } from '../../../types/retail-product';
import ProductCard from './product-card';
import ProductSkeleton from './product-skeleton';

export default function Products({ data, loading }: { data: Product[], loading: boolean }) {
  return (
    <section className='bg-white'>
      <div className='mx-auto max-w-2xl px-4 py-8 sm:px-6 sm:py-12 lg:max-w-7xl lg:px-0'>
        <div className='flex justify-center'>
          <div className='mt-8 grid grid-cols-1 gap-y-12 sm:grid-cols-2 sm:gap-x-6 lg:grid-cols-3 xl:gap-x-8'>
            {loading
              ? Array.from({ length: 3 }).map(() => (
                <ProductSkeleton key={crypto.getRandomValues(new Uint32Array(1))[0]} />
              ))
              : data?.map((product) => <ProductCard key={product.id} data={product} />)}
          </div>
        </div>
      </div>
    </section>
  );
}
