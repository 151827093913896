import HttpHelpers from './helpers';

const DeliveryRequestsApiEndpoints = {
  createDeliveryRequest: (data: {
    latitude: string;
    longitude: string;
  }): Promise<any> => HttpHelpers.authenticatedAxios
    .post('/api/v1/delivery_requests', data)
    .then((response) => response.data),
};

export default DeliveryRequestsApiEndpoints;
