import { useRef } from 'react';
import { XMarkIcon } from '@heroicons/react/24/solid';

interface PaymentIframeProps {
  iframeUrl: string | null;
  setIframeUrl: (url: string | null) => void;
}

export default function PaymentIframe({
  iframeUrl,
  setIframeUrl,
}: PaymentIframeProps) {
  const iframeRef = useRef<HTMLIFrameElement>(null);

  if (!iframeUrl) return null;
  return (
    <div className='fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-70'>
      <div className='relative w-full h-full max-w-full max-h-full'>
        <iframe
          ref={iframeRef}
          src={iframeUrl}
          className='w-full h-full rounded-none'
          title='Payment Modal'
        />
        <button
          className='absolute top-4 right-4 text-white text-xl hover:text-gray-300'
          onClick={() => setIframeUrl(null)}
          type='button'
        >
          <XMarkIcon aria-hidden='true' className='h-6 w-6' />
        </button>
      </div>
    </div>
  );
}
