import { CartApiResponse } from '../types/cart';
import HttpHelpers from './helpers';

const CartApi = {
  fetchCart: (cartId: string | null): Promise<CartApiResponse> => HttpHelpers.unAuthenticatedAxios
    .post('/api/v1/carts/fetch_cart', { cart_id: cartId })
    .then((response) => response.data),
  addProductToCart: async (cartId: string | null, productId: string, quantity: number) => {
    const response = await HttpHelpers.authenticatedAxios.post('/api/v1/carts/add_product', {
      cart_id: cartId,
      product_id: productId,
      quantity,
    });

    return response.data;
  },
  removeProductFromCart: async (cartId: string | null, productId: string) => {
    const response = await HttpHelpers.authenticatedAxios.post('/api/v1/carts/remove_product', {
      cart_id: cartId,
      product_id: productId,
    });
    return response.data;
  },
  increaseProductQuantity: async (cartId: string | null, productId: string) => {
    const response = await HttpHelpers.authenticatedAxios.post('/api/v1/carts/increase_quantity', {
      cart_id: cartId,
      product_id: productId,
    });
    return response.data;
  },
  decreaseProductQuantity: async (cartId: string | null, productId: string) => {
    const response = await HttpHelpers.authenticatedAxios.post('/api/v1/carts/decrease_quantity', {
      cart_id: cartId,
      product_id: productId,
    });
    return response.data;
  },
  bindCartToUser: async (cartId: string) => {
    const response = await HttpHelpers.authenticatedAxios.post('/api/v1/carts/bind_to_user', { cart_id: cartId });
    return response.data;
  },
};

export default CartApi;
