/* eslint-disable react/no-array-index-key */
import { Link } from 'react-router-dom';
import useEmblaCarousel from 'embla-carousel-react';
import { useEffect, useCallback, useState } from 'react';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/solid';
import bannerOne from '../../../assets/images/banner1.jpg';
import bannerTwo from '../../../assets/images/banner2.jpg';
import i18n from '../../../i18n';

const sliderData = [
  {
    id: 1,
    title: 'عيش تجربة الشاحن المتنقل الذكي مع شايزر',
    subtitle: 'شاحن شايزر المتنقل والمتبدل على يمناك.. دايم مشحون، ومعك وين ما تكون!',
    url: bannerOne,
  },
  {
    id: 2,
    title: 'شاحن شايزر جاهز مجهز لخدمتك بأي وقت ومكان',
    subtitle: 'إذا فضى عليك بأي مكان وفي أي وقت، تقدر تبدله بواحد مشحون فُل بثواني من خلال شبكة مكاين شايزر المنتشرة في أكثر من 1000 موقع بالمملكة',
    url: bannerTwo,
  },
];

export default function Hero() {
  const [emblaRef, embla] = useEmblaCarousel({ loop: true });
  const [selectedIndex, setSelectedIndex] = useState(0);

  const scrollPrev = useCallback(() => {
    if (embla) embla.scrollPrev();
  }, [embla]);

  const scrollNext = useCallback(() => {
    if (embla) embla.scrollNext();
  }, [embla]);

  const onSelect = useCallback(() => {
    if (embla) setSelectedIndex(embla.selectedScrollSnap());
  }, [embla]);

  useEffect(() => {
    if (embla) {
      embla.on('select', onSelect);
      embla.reInit({ direction: i18n.language === 'ar' ? 'rtl' : 'ltr' });
    }
  }, [embla, i18n.language, onSelect]);

  return (
    <section
      className='relative overflow-hidden w-full mx-auto flex items-center justify-center lg:h-[75vh]'
      ref={emblaRef}
    >
      <div className='flex'>
        {sliderData.map((item) => (
          <div className='embla__slide relative h-full w-full' key={item.id}>
            {/* the image */}
            <img className='w-full h-full object-cover' src={item.url} alt={item.title} />

            <div className='absolute inset-0 bg-gray-900/75 sm:bg-transparent sm:from-gray-900/95 sm:to-gray-900/25 ltr:sm:bg-gradient-to-r rtl:sm:bg-gradient-to-l' />

            <div className='absolute inset-0 flex items-center justify-center sm:justify-start p-4 sm:p-6 lg:px-28'>
              <div className='relative max-w-xl text-center lg:text-left rtl:lg:text-right'>
                <h1 className='text-3xl font-extrabold text-primary sm:text-4xl'>
                  {item.title}
                </h1>
                <p className='mt-4 max-w-lg text-white sm:text-xl/relaxed'>
                  {item.subtitle}
                </p>
                <div className='mt-8 flex justify-center sm:justify-start gap-4 text-center'>
                  <Link
                    to='/'
                    className='block rounded bg-primary px-12 py-3 text-sm font-medium text-white shadow hover:bg-lime-600 focus:outline-none focus:ring active:bg-lime-500 sm:w-auto'
                  >
                    اطلبه الآن
                  </Link>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* Previous/Next Arrows */}
      <button
        className='absolute left-4 top-1/2 transform -translate-y-1/2 bg-primary/50 text-white p-2 rounded-full hover:bg-primary hidden sm:block'
        onClick={scrollPrev}
        type='button'
      >
        {i18n.language === 'ar'
          ? <ChevronLeftIcon className='h-5 w-5' strokeWidth={3} />
          : <ChevronRightIcon className='h-5 w-5' strokeWidth={3} />}
      </button>
      <button
        className='absolute right-4 top-1/2 transform -translate-y-1/2 bg-primary/50 text-white p-2 rounded-full hover:bg-primary hidden sm:block'
        onClick={scrollNext}
        type='button'
      >
        { i18n.language === 'ar'
          ? <ChevronRightIcon className='h-5 w-5' strokeWidth={3} />
          : <ChevronLeftIcon className='h-5 w-5' strokeWidth={3} />}
      </button>

      <div className='absolute bottom-4 left-0 right-0 flex justify-center space-x-2 rtl:space-x-reverse'>
        {sliderData.map((_, index) => (
          <button
            key={index}
            className={`w-3 h-3 rounded-full ${selectedIndex === index ? 'bg-primary' : 'bg-gray-500'}`}
            onClick={() => embla && embla.scrollTo(index)}
            type='button'
          />
        ))}
      </div>
    </section>
  );
}
