import { createBrowserRouter, Navigate } from 'react-router-dom';
import Layout from '../views/layout';
import RequireAuth from '../components/require-auth';
import Home from '../views/home';
import Account from '../views/account';
import UpdateAccount from '../views/account/update';
import AccountCredentials from '../views/account/credentials';
import AuthLayout from '../views/auth';
import Login from '../views/auth/login';
import VerifyIdentity from '../views/auth/verify-identity';
import NotFound from '../views/not-found';
import env from '../env';
import ItemActivation from '../views/activation';
import InitializeApp from '../components/initialize-app';
import CompanyProfile, { CompanyProfileLoader } from '../views/company-profile';
import CompanyProfileError from '../views/company-profile/company-profile-error';
import Download from '../views/download';
import ProductDetails from '../views/product';
import Checkout from '../views/checkout';
import PaymentMethods from '../views/account/payment-methods';
import CheckoutDetails from '../views/checkout/details';

const router = createBrowserRouter([
  {
    path: '/',
    element: <Navigate to='/ar' replace />,
  },
  {
    path: ':locale',
    element: (
      <InitializeApp>
        <Layout />
      </InitializeApp>
    ),
    children: [
      {
        index: true,
        element: <Home />,
      },
      {
        path: 'account',
        element: (
          <RequireAuth>
            <Account />
          </RequireAuth>
        ),
        children: [
          {
            path: 'update',
            element: (
              <RequireAuth>
                <UpdateAccount />
              </RequireAuth>
            ),
          },
          {
            path: 'credentials',
            element: (
              <RequireAuth>
                <AccountCredentials />
              </RequireAuth>
            ),
          },
          {
            path: 'payment-methods',
            element: (
              <RequireAuth>
                <PaymentMethods />
              </RequireAuth>
            ),
          },
        ],
      },
      {
        path: 'product/:id',
        element: <ProductDetails />,
      },
      {
        path: 'checkout/:id',
        element: (
          <RequireAuth>
            <CheckoutDetails />
          </RequireAuth>
        ),
      },
      {
        path: 'checkout',
        element: (
          <RequireAuth>
            <Checkout />
          </RequireAuth>
        ),
      },
      {
        path: 'activation/item/:qrCode',
        element: (
          <RequireAuth>
            <ItemActivation />
          </RequireAuth>
        ),
      },
    ],
  },
  {
    path: ':locale/public',
    element: (
      <InitializeApp>
        <Layout />
      </InitializeApp>
    ),
    children: [
      {
        path: 'complete_company_profile/:verificationCode',
        loader: CompanyProfileLoader,
        element: <CompanyProfile />,
        errorElement: <CompanyProfileError />,
      },
    ],
  },
  {
    path: ':locale/auth',
    element: (
      <InitializeApp>
        <AuthLayout />
      </InitializeApp>
    ),
    children: [
      {
        path: 'login',
        element: <Login />,
      },
      {
        path: 'verify_identity',
        element: <VerifyIdentity />,
      },
    ],
  },
  {
    path: ':locale/download',
    element: (
      <InitializeApp>
        <Download />
      </InitializeApp>
    ),
  },
  {
    path: '*',
    element: (
      <InitializeApp>
        <NotFound />
      </InitializeApp>
    ),
  },
], {
  basename: env.PUBLIC_URL,
});

export default router;
