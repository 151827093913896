import { ShoppingBagIcon } from '@heroicons/react/24/outline';

export interface CartIconProps {
  itemCount: number;
  onClick: () => void;
}

export default function CartIcon({ itemCount, onClick }: CartIconProps) {
  return (
    <div className='relative'>
      <button type='button' onClick={onClick}>
        <ShoppingBagIcon
          aria-hidden='true'
          className='h-6 w-6 flex-shrink-0 text-secondary hover:text-primary'
        />
      </button>
      {itemCount > 0 && (
        <span className='absolute right-0 top-0 -translate-y-1/2 translate-x-1/2 transform block h-4 w-4 rounded-full bg-red-700 text-[10.5px] text-white text-center font-base'>
          {itemCount}
        </span>
      )}
    </div>
  );
}
