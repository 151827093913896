import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { CheckIcon } from '@heroicons/react/24/outline';
import { renderPaymentIcon } from '../../../helpers';

interface PaymentMethodCardProps {
  data: any;
  selectedCardId: string | null;
  onSelect: (id: number) => void;
}

export default function PaymentMethodCard({ data, selectedCardId, onSelect }: PaymentMethodCardProps) {
  const { t } = useTranslation();

  return (
    <div
      className={classNames(
        'mb-2 p-4 rounded-lg border border-gray-200 bg-white shadow-sm',
        { 'border-primary border-2': selectedCardId === data.id },
      )}
      onClick={() => onSelect(data.id)}
      onKeyDown={(e) => e.key === 'Enter' && onSelect(data.id)}
      role='button'
      tabIndex={0}
    >
      <div className='flex items-center'>
        {renderPaymentIcon(data.attributes.brand)}
        <div className='flex-1'>
          <p className='text-sm font-medium text-black'>
            {data.attributes.card_number}
          </p>
          <p className='text-sm text-secondary'>
            {t('account.paymentMethods.savedCards.expires', {
              date: data.attributes.expiry_date,
            })}
          </p>
        </div>
        {selectedCardId === data.id ? (
          <CheckIcon aria-hidden='true' className='h-6 w-6 text-primary' />
        ) : (
          <span className='text-sm text-gray-500'>{t('account.paymentMethods.selectMethod')}</span>
        )}
      </div>
    </div>
  );
}
