import { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { StarIcon } from '@heroicons/react/20/solid';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import ProductSkeleton from './product-skeleton';
import DEFAULT_IMAGE from '../../../assets/images/default-product.jpg';
import useCart from '../../../hooks/use-cart';
import { Product } from '../../../types/retail-product';
import Spinner from '../../../components/spinner';

export default function ProductCard({ data }: { data: Product }) {
  const [imageLoaded, setImageLoaded] = useState(false);
  const [showShimmer, setShowShimmer] = useState(true);
  const { addItemToCart, isAddingToCart } = useCart();
  const imageUrl = data?.attributes?.logo?.url || DEFAULT_IMAGE;
  const { t } = useTranslation();
  const { locale } = useParams();

  const handleAddToCart = () => {
    if (data?.id) {
      addItemToCart(data.id, 1);
    }
  };

  useEffect(() => {
    const shimmerTimeout = setTimeout(() => {
      if (!imageLoaded) {
        setShowShimmer(true);
      }
    }, 300);

    return () => clearTimeout(shimmerTimeout);
  }, [imageLoaded]);

  const handleImageLoad = () => {
    setImageLoaded(true);
    setShowShimmer(false);
  };

  if (showShimmer) {
    return (
      <ProductSkeleton>
        <img
          alt={data?.attributes?.name || 'Product image'}
          src={imageUrl}
          className='hidden'
          onLoad={handleImageLoad}
          onError={handleImageLoad}
        />
      </ProductSkeleton>
    );
  }

  return (
    <div className='relative transition-transform duration-300 transform hover:-translate-y-2'>
      <Link to={`/${locale}/product/${data?.id}`} className='relative block'>
        <div className='relative h-72 w-full overflow-hidden rounded-lg'>
          <img
            alt={data?.attributes?.name || 'Product image'}
            src={imageUrl}
            className='h-full w-full object-cover object-center'
            onLoad={handleImageLoad}
            onError={handleImageLoad}
          />
        </div>
        <div className='relative mt-4'>
          <h3 className='text-sm font-medium text-gray-900'>
            {data?.attributes?.name || 'Product Name'}
          </h3>
        </div>
      </Link>
      <div className='flex items-center justify-between mt-3'>
        <div className='flex items-center'>
          <div className='flex items-center' dir='ltr'>
            {[0, 1, 2, 3, 4].map((rating) => (
              <StarIcon
                key={rating}
                aria-hidden='true'
                className={classNames(
                  rating < 4 ? 'text-primary' : 'text-gray-300',
                  'h-5 w-5 flex-shrink-0',
                )}
              />
            ))}
          </div>
          <p className='ml-2 rtl:mr-2 text-sm text-secondary'>{t('productDetails.reviews.reviewsCount', { count: 20 })}</p>
        </div>
        {data.attributes.in_stock ? (
          <button
            className='text-sm font-medium text-primary'
            type='button'
            onClick={(e) => {
              e.stopPropagation();
              handleAddToCart();
            }}
            disabled={isAddingToCart}
          >
            {isAddingToCart ? <Spinner /> : t('cart.addToCart')}
          </button>
        ) : (
          <button
            className='text-sm font-medium text-red-500'
            type='button'
          >
            {t('productDetails.availability.outOfStock')}
          </button>
        )}
      </div>
      <div className='absolute inset-x-0 top-0 flex h-72 items-end justify-end overflow-hidden rounded-lg p-4 pointer-events-none'>
        <div
          aria-hidden='true'
          className='absolute inset-x-0 bottom-0 h-36 bg-gradient-to-t from-black opacity-50'
        />
        <p className='relative text-lg font-semibold text-white pointer-events-auto'>
          {data?.attributes?.base_price ? `${data.attributes.base_price} ${t('shared.currency')}` : 'N/A'}
        </p>
      </div>
    </div>
  );
}
