import { Fragment } from 'react';

const faqs = [
  {
    question: 'هل توفرون خصومات أو عروض خاصة للشراء بالجملة؟',
    answer: 'يوجد عروض خاصة للكميات التي تزيد عن ألف شاحن، يمكنك التواصل معنا للحصول على عرض سعر مخصص',
  },
  {
    question: 'كيف يتم تخصيص الشواحن بشعار شركتي؟',
    answer: 'لا توجد إمكانية لتخصيص الشواحن بشعار الشركة أو المنشأة',
  },
];

export default function FAQs() {
  return (
    <dl>
      {faqs.map((faq) => (
        <Fragment key={faq.question}>
          <dt className='mt-10 font-medium text-gray-900'>{faq.question}</dt>
          <dd className='prose prose-sm mt-2 max-w-none text-secondary'>
            <p>{faq.answer}</p>
          </dd>
        </Fragment>
      ))}
    </dl>
  );
}
