import HttpHelpers from './helpers';

const PurcahsesApiEndpoints = {
  purchaseQuote: (data?: any) => HttpHelpers.authenticatedAxios
    .post('/api/v2/customer/purchases/quote', data)
    .then((response) => response.data.data),
  purchaseCheckout: (data: any) => HttpHelpers.authenticatedAxios
    .post('/api/v2/customer/purchases/checkout', data)
    .then((response) => response.data.data),
  getPurchaseDetails: (id: string) => HttpHelpers.authenticatedAxios
    .get(`/api/v2/customer/purchases/${id}`)
    .then((response) => response.data.data),
};

export default PurcahsesApiEndpoints;
