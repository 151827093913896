import React from 'react';

export default function ProductSkeleton({ children }: { children?: React.ReactNode }) {
  return (
    <div className='animate-pulse w-[288px]'>
      <div className='relative h-72 w-full overflow-hidden rounded-lg'>
        <div className='h-full w-full bg-gray-300' />
      </div>
      <div className='relative mt-4'>
        <div className='h-4 w-full bg-gray-300 rounded' />
      </div>
      <div className='flex items-center justify-between mt-3'>
        <div className='flex items-center space-x-2 rtl:space-x-reverse'>
          {[0, 1, 2, 3, 4].map((index) => (
            <div key={index} className='h-5 w-5 bg-gray-300 rounded-full' />
          ))}
        </div>
        <div className='h-4 w-24 bg-gray-300 rounded' />
      </div>
      {children}
    </div>
  );
}
